import React, {useState, useEffect} from 'react';
import Layout from '../../layout'
import Loader from '../../loading';

import { Container, Row, Col, Table, Button, Breadcrumb } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {DDMMYYYYFormat} from '../../../helpers/dateTime';

const Referrals = ({location}) => {
  const [referrals, setReferrals] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  
  const getReferrals = () => {
    setShowLoader(true);

    const url=`${constants.BACKEND_URL}/referrals/customer`
    Fetch.get(url).then(res => {
      if (res.error) {
        alert(res.error)
      }
      setReferrals(res.data)
      setShowLoader(false);

    })
    
  }

  useEffect(() => {
    getReferrals();
    return () => {
    }
  }, [])

  return (
    <Layout pageInfo={{ pageName: "referrals" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item active>Referrals</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col className="text-center mb-2">
            <h5>Referrals</h5>
            <h6><small>Showing Your referrals</small></h6>
          </Col>
        </Row>
        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Date Joined</th>
                <th>Referrals</th>
                <th>Gender</th>
                <th>Join Method</th>
                <th className="text-center">Bonus Received</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {referrals && Array.isArray(referrals) ? 
                referrals.map( (item, index) => {
                  return (
                    <tr key={item.id}>
                      <td>{index+1}</td>
                      <td>{DDMMYYYYFormat(item.time)}</td>
                      <td>{item.downlineCount}</td>
                      <td>{item.gender}</td>
                      <td>{item.howYouKnowUs}</td>
                      <td className="text-center">${Number(item.downlineBonus).toFixed(2)}</td>
                      <td className="text-center">
                        <Button 
                          onClick={() => navigate(`/profile/referrals/${item.referralCode}`)}
                          disabled={Number(item.downlineCount) === 0}
                        >View Referrals</Button>
                      </td>
                    </tr>
                  )
                }) : <tr></tr>
              }
            </tbody>
          </Table>
        </Row>

      </Container>
    </Layout>
  )
}

export default Referrals