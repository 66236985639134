import * as jwtDecode from 'jwt-decode'
import LocalStorage from '../components/localStorage';

const getDecodedToken = () => {
  if (!LocalStorage.get('jwt')) {
    return false;
  }
  return jwtDecode(LocalStorage.get('jwt')); 
}

export default getDecodedToken