import React, {useState, useEffect} from 'react';
import Layout from '../../layout'
import Loader from '../../loading';

import { Container, Row, Col, Table, Tabs, Tab, Breadcrumb } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {DDMMYYYYFormat} from '../../../helpers/dateTime';

const BonusTransactions = ({location}) => {
  const [bonusTransactions, setBonusTransactions] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  const [key, setKey] = useState('all');

  const getBonusTransactions = () => {
    setShowLoader(true);

    const url=`${constants.BACKEND_URL}/points-trx/customer`
    Fetch.get(url).then(res => {
      if (res.error) {
        alert(res.error)
      }
      setBonusTransactions(res.data)
      setShowLoader(false);

    })
    
  }

  useEffect(() => {
    getBonusTransactions();
    return () => {
    }
  }, [])

  const tableBodyUsedPoints = () => {
    let tempId = 0;
    let whiteColor = true;
    return bonusTransactions.filter(t => t.amount < 0).map((item, index) => {
      if (index !== 0) {
        if (tempId !== item.orderId) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (
        <tr key={item.id} className={`${rowBg}`}>
          <td>{item.orderId}</td>
          <td>{DDMMYYYYFormat(item.time)}</td>
          <td>{item.type.toUpperCase()}</td>
          <td className="text-center">{Number(item.amount) < 0 ? <span className="text-danger">(${Number(item.amount * -1).toFixed(2)})</span> : <span className="text-success">${Number(item.amount).toFixed(2)}</span>}</td>
        </tr>
      )
      tempId = item.orderId;
      return result;
      
    })
  }
  const tableBodyBonusAll= () => {
    let tempId = 0;
    let whiteColor = true;
    return bonusTransactions.map((item, index) => {
      if (index !== 0) {
        if (tempId !== item.orderId) whiteColor = !whiteColor;
      }
      const rowBg = whiteColor ? 'odd' : 'even';
      const result = (
        <tr key={item.id} className={`${rowBg}`}>
          <td>{item.orderId}</td>
          <td>{DDMMYYYYFormat(item.time)}</td>
          <td>{item.type.toUpperCase()}</td>
          <td className="text-center">{Number(item.amount) < 0 ? <span className="text-danger">(${Number(item.amount * -1).toFixed(2)})</span> : <span className="text-success">${Number(item.amount).toFixed(2)}</span>}</td>
        </tr>
      )
      tempId = item.orderId;
      return result;
      
    })
  }

  return (
    <Layout pageInfo={{ pageName: "bonusTransactions" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item active>Statement Of Account</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col className="text-center mb-2">
            <h5>Statement Of Account</h5>
            <h6><small>Showing Your Statement of Account</small></h6>
          </Col>
        </Row>
        <Row>
          
        </Row>
      
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey="all" title="All">
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Disbursed On</th>
                  <th>Type</th>
                  <th className="text-center">Bonus</th>
                </tr>
              </thead>
              <tbody>
                {bonusTransactions && Array.isArray(bonusTransactions) ? 
                  tableBodyBonusAll() : <></>
                }
              </tbody>
            </Table>
          </Tab>
          {/* <Tab eventKey="confirmed" title="Confirmed">
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center">Bonus</th>
                  <th>Status</th>
                  <th>Disbursed On</th>
                  <th>Type</th>
                  <th>Order ID</th>
                </tr>
              </thead>
              <tbody>
                {bonusTransactions && Array.isArray(bonusTransactions) ? 
                  bonusTransactions.map( (item, index) => {
                    return item.disbursed && item.type === 'bonus' ?  (
                      <tr key={item.id}>
                        <td className="text-center">{Number(item.amount) < 0 ? <span className="text-danger">(${Number(item.amount * -1).toFixed(2)})</span> : <span className="text-success">${Number(item.amount).toFixed(2)}</span>}</td>
                        <td>{item.disbursed ? 'Confirmed' : 'Pending'}</td>
                        <td>{DDMMYYYYFormat(item.disbursedDate)}</td>
                        <td>{item.type.toUpperCase()}</td>
                        <td>{item.orderId}</td>
                      
                      </tr>
                    ) : <></>
                  }) : <tr></tr>
                }
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="pending" title="Pending" >
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center">Bonus</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Order ID</th>
                </tr>
              </thead>
              <tbody>
                {bonusTransactions && Array.isArray(bonusTransactions) ? 
                  bonusTransactions.map( (item, index) => {
                    return !item.disbursed ?  (
                      <tr key={item.id}>
                        <td className="text-center">{Number(item.amount) < 0 ? <span className="text-danger">(${Number(item.amount * -1).toFixed(2)})</span> : <span className="text-success">${Number(item.amount).toFixed(2)}</span>}</td>
                        <td>{item.disbursed ? 'Confirmed' : 'Pending'}</td>
                        <td>{item.type.toUpperCase()}</td>
                        <td>{item.orderId}</td>
                      
                      </tr>
                    ) : <></>
                  }) : <tr></tr>
                }
              </tbody>
            </Table>
          </Tab> */}
          <Tab eventKey="used" title="Used" >
            <Table responsive>
              <thead>
                <tr>
                  <th className="text-center">Bonus</th>
                  
                  <th>Used On</th>
                  <th>Type</th>
                  <th>Order ID</th>
                </tr>
              </thead>
              <tbody>
                {bonusTransactions && Array.isArray(bonusTransactions) ? 
                  tableBodyUsedPoints() : <></>
                  
                }
              </tbody>
            </Table>
          </Tab>
        </Tabs>

      </Container>
    </Layout>
  )
}

export default BonusTransactions