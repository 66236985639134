import LocalStorage from '../components/localStorage';
import { navigate } from "gatsby"
import constants from '../helpers/constants'
import * as jwtDecode from 'jwt-decode'

const headers = {
  "Content-type": "application/json",
  // "Access-Control-Allow-Origin": "*",
}

const prevQueries = []

const Fetch = {
  getResultByContentType: function (result) {
    const contentType = result.headers.get("content-type");
    if (contentType && contentType.indexOf("application/pdf") !== -1) {
      return result.body
    }
    if (contentType && contentType.indexOf("application/json") !== -1) {
      return result.json()
    } 
    return result.text()
  },
  getToken: function() {
    if (!LocalStorage.get('SetMoreJWT')) {
      // navigate('/login')
    }
    return `Bearer ${LocalStorage.get('SetMoreJWT')}`
  },
  getSetMoreToken: async function (url) {
   await fetch(url)
    .then(response => response.json())
    .then(values=> {
      if (values.response) {
        LocalStorage.set('SetMoreJWT', values.data.token.access_token)
      }
    })
    return;
  },
  get: async function (url) {
    headers.Authorization = this.getToken();
    const obj = {
      url,
      options: {
        headers,
      }
    }
    prevQueries.push(obj)
    
    const result = await fetch(obj.url, obj.options)
    .then(response => checkResponseStatus(response))
  
    if (result) {
      return this.getResultByContentType(result);
    }
    return;
  },
  post: async function(url, data) {
    headers.Authorization = this.getToken();
    const obj = {
      url,
      data,
      options:{
        method: 'POST',
        headers,
        body: data
      }
    }
    prevQueries.push(obj)
    
    const result = await fetch(obj.url, obj.options )
    .then(response => checkResponseStatus(response));

    if (result) {
      return this.getResultByContentType(result);
    }
    return;
  },
  postRefreshToken: async function(url, data) {
    headers.Authorization = this.getToken();
    const result = await fetch(url, {
      method: 'POST',
      headers,
      credentials: 'include',
      body: data
    })
    .then(response => checkResponseStatus(response));
    
    if (result) {
      return this.getResultByContentType(result);
    }
    return;
  },
  put: async function(url, data) {
    headers.Authorization = this.getToken();
    const obj = {
      url,
      data,
      options: {
        method: 'PUT',
        headers,
        credentials: 'include',
        body: data
      }
    }
    prevQueries.push(obj)
    
    const result = await fetch(obj.url, obj.options )
    .then(response => checkResponseStatus(response));

    if (result) {
      return this.getResultByContentType(result);
    }
    return;
  },
  patch: async function(url, data) {
    headers.Authorization = this.getToken();
    const obj = {
      url,
      data,
      options: {
        method: 'PATCH',
        headers,
        credentials: 'include',
        body: data
      }
    }
    prevQueries.push(obj)
    
    const result = await fetch(obj.url, obj.options )
    .then(response => checkResponseStatus(response));

    if (result) {
      return this.getResultByContentType(result);
    }
    return;
  },
  delete: async function(url) {
    headers.Authorization = this.getToken();
    const obj = {
      url,
      options: {
        method: 'DELETE',
        headers,
        credentials: 'include',
      }
    }
    prevQueries.push(obj)
    
    const result = await fetch(obj.url, obj.options )
    .then(response => checkResponseStatus(response));

    if (result) {
      return this.getResultByContentType(result);
    }
    return;
  }
}

const checkResponseStatus = async (response) => {
  if (response.status === 401) {
    //Refresh token
    await Fetch.getSetMoreToken(`https://developer.setmore.com/api/v1/o/oauth2/token?refreshToken=${constants.SETMORE_TOKEN}`)
    // if (newAccessToken) {
    //   setNewJWT(newAccessToken)
    // }

    // if (!newAccessToken) {
      //navigate to login
      // navigate("/login");
      // return response;
    // }
    //Update response object by calling the previous query
    // const firstItemInStack = prevQueries.shift()
    // response = await callPreviousQuery(firstItemInStack);
    return response;
    
    
  } else {
    let item = {};
    if (response.url.search('refresh-token') === -1) item = prevQueries.shift();
    return response;
  }
}

// const getNewJWT = async () => {
//   if (LocalStorage.get('SetMoreJWT')) {
//     // const decodedToken = jwtDecode(LocalStorage.get('SetMoreJWT'));
//     // const data =  JSON.stringify(
//     //   {
//     //     id: decodedToken.id,
//     //     username:decodedToken.username,
//     //     role: decodedToken.role ? decodedToken.role: "",
//     //   }
//     // )
  
 
//     if (!result) return false
  
//     if (result.statusCode === 404) {
//       return false;
//     }
//     return result.access_token;
//   }
//   return false;
// }

const setNewJWT = (newAccessToken) => {
  if (newAccessToken) {
    LocalStorage.set('jwt', newAccessToken);
  }
}

const callPreviousQuery = async (prevQuery) => {
  prevQuery.options.headers.Authorization = `Bearer ${LocalStorage.get('SetMoreJWT')}`;
  const response = await fetch(prevQuery.url, prevQuery.options)
  .then(response => response);

  return response
}

export default Fetch;