import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Table, Breadcrumb, Badge, Button } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Layout from '../../layout'
import Loader from '../../loading';
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import styles from './orderDetails.module.css'

const OrderDetails = (props) => {
  const [showLoader, setShowLoader] = useState(false)
  const [orderItems, setOrderItems] = useState([])

  const getOrderDetails = (params) => {
    setShowLoader(true);
    const url=`${constants.BACKEND_URL}/order-items/customer/order/${props.orderId}`
    Fetch.get(url).then(data => {
      setOrderItems(data.data)
      setShowLoader(false);
    })
  }

  useEffect(() => {
    getOrderDetails()
    return () => {
    }
  }, [])

  const getTotalPrice = (items) => {
    let totalPrice = 0;
    items.forEach(item => totalPrice += item.priceAfterBonusOffset ? item.priceAfterBonusOffset : item.priceAfterDiscount);
    return totalPrice;
  }

  const getItemTypeImages = (itemType) => {
    switch (itemType) {
      case 'LS':
      case 'PRIMO LS':
        return `https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/5765769151578983119-128.png`
        break;
      case 'SP':
      case 'PRIMO SP':
        return `https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/Short-Pants-icon.png`
        break;
      case 'LP':
      case 'PRIMO LP':
        return `https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/9038427701582094054-128.png`
        break;
      case 'SBV':
      case 'DBV':
      case 'PRIMO SBV':
      case 'PRIMO DBV':
        return `https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/20798268141578983127-128.png`
        break;
      case 'SBJ':
      case 'DBJ':
      case 'TUXJ':
      case 'PRIMO SBJ':
      case 'PRIMO DBJ':
      case 'PRIMO TUXJ':
        return `https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/suit.png`
        break;
      default:
        // return `https://rdb-design-images.s3-ap-southeast-1.amazonaws.com/placeholder.png`
        return ''
        break;
    }
    
    
  }

  const getItemDesign = (itemDesign) => {
    if (itemDesign) {
      return Object.entries(JSON.parse(itemDesign)).map(item => {
        return (
          <>
            {item[1]['sectionDesc'] && item[1]['optionDesc'] ? (
              <div>{item[1]['sectionDesc']}: <b>{item[1]['optionDesc']}</b></div>
            ) : <></>}
          </>
        )
      })
    }
  }
  

  return (
    <Layout pageInfo={{ pageName: "orderDetails" }}> 
      <Loader show={showLoader}></Loader>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item onClick={() => navigate(props && props.location && props.location.state && props.location.state.from ? props.location.state.from : '')}>{props && props.location && props.location.state && props.location.state.pageName ? props.location.state.pageName : ''}</Breadcrumb.Item>
          <Breadcrumb.Item active>Details</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col className="mb-2">
              <h1 className="float-left ">Order Details #{props.orderId}</h1>
              <div className={`${styles.orderTotalPrice} float-right`}>{orderItems && orderItems.length > 0 ? orderItems.length : 0} items in total: <b>${Number(getTotalPrice(orderItems)).toFixed(2)}</b></div>
              {/* <h6><small>Showing Items Ordered</small></h6> */}
            </Col>
          </Row>
          <Row>
            <div className={`${styles.itemListWrapper} d-flex flex-row flex-wrap justify-content-between`}>
                {orderItems && Array.isArray(orderItems) ? 
                  orderItems.map(item => {
                    return (
                        <>
                          <div className={`${styles.itemCard}`}>
                            <div className={`${styles.infoSection} d-flex flex-row`}>
                              <img src={getItemTypeImages(item.itemType)} style={{maxWidth: '100%'}}/>
                              <div className={`${styles.itemDetails}`}>
                                {item.itemType}<br /> 
                                <small>{item.item_desc}</small><br /> 
                                {/* <b>Nett Paid: S${Number(item.priceAfterBonusOffset ? item.priceAfterBonusOffset : item.priceAfterDiscount).toFixed(2)}</b><br />
                                <span className="text-muted">R$ {Number(item.bonusOffset).toFixed(2)} used</span><br /> */}
                              </div>
                              <div className="ml-auto">
                                <Badge variant="info">{item.status ? item.status : 'Item Status: Processing'}</Badge><br /> 
                              </div>
                            </div>
                            {item.design ? 
                              <div className={`${styles.designSection}`} style={{backgroundColor: '#f9f9f9', padding: '1rem'}}>
                              {getItemDesign(item.design)}
                              </div>
                              : <></>
                            }
                          </div>
                        </>
                    )
                  }) : <></>
                }
              </div>
          </Row>
          <Row>
            <Col className="text-left mb-3"><Button variant="secondary" onClick={() => navigate(props && props.location && props.location.state && props.location.state.from ? props.location.state.from : '')}>&lt; Back</Button></Col>
          </Row>
      </Container>
    </Layout>
  )
}

export default OrderDetails