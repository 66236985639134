import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Card, Button, Alert, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Layout from '../layout'
import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';
import styles from './customerProfile.module.css'
import {DDMMYYYYFormat} from '../../helpers/dateTime'

const CustomerProfile = ({location, pageInfo}) => {
  const [customerData, setCustomerData] = useState({})
  const [showInfoAlert, setShowInfoAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0)
  const [rdbDollarsAmount, setRdbDollarsAmount] = useState(0)
  const [totalReferrals, setTotalReferrals] = useState(0)
  const [levelOnePercentage, setLevelOnePercentage] = useState(0)
  const [levelTwoPercentage, setLevelTwoPercentage] = useState(0)
  const [firstTimeDiscount, setFirstTimeDiscount] = useState(0)

  const getCustomerProfile = () => {
    const url=`${constants.BACKEND_URL}/customers/customer/myProfile`
    Fetch.get(url).then(data => {
      setCustomerData(data.data)
    })
  }

  const getPendingOrdersCount = () => {
    const url=`${constants.BACKEND_URL}/orders/customer/pending`
    Fetch.get(url).then(data => {
      if (!data.status){}
      setPendingOrdersCount(data && data.data ? data.data[1] : 0)
    })
  }

  const getRDBDollarsBalance = async () => {
    const url=`${constants.BACKEND_URL}/points-trx/customer/bonus/balance`
    Fetch.get(url).then(res => {
      if (!res || !res.data){return}
      setRdbDollarsAmount(Number(res.data.balance))
    })
  }

  const getTotalReferrals = async () => {
    const url=`${constants.BACKEND_URL}/referrals/customer/totalReferrals`
    Fetch.get(url).then(res => {
      if (!res || !res.data){return}
      setTotalReferrals(Number(res.data))
    })
  }
  
  const getLevelOneAndTwoPercentage = (params) => {
    const url=`${constants.BACKEND_URL}/settings/level1BonusPercentage`
    Fetch.get(url).then(res => {
     if (res && res.data && res.data.value) {
       setLevelOnePercentage(res.data.value)
     }
    })
    const url2=`${constants.BACKEND_URL}/settings/level2BonusPercentage`
    Fetch.get(url2).then(res => {
      if (res && res.data && res.data.value) {
        setLevelTwoPercentage(res.data.value)
      }
     })
  }

  const getFirstTimeDiscount = () => {
    const url=`${constants.BACKEND_URL}/settings/activateAccount`
    Fetch.get(url).then(res => {
      if (!res && !res.data) return;
      setFirstTimeDiscount(res.data.value)
    })
  }

  useEffect(() => {
    getCustomerProfile();
    getPendingOrdersCount();
    getRDBDollarsBalance();
    getTotalReferrals()
    getLevelOneAndTwoPercentage();
    getFirstTimeDiscount();
    if (location && location.state && location.state.passwordChange) {
      setShowInfoAlert(true);
      setAlertMsg('Password changed successfully')
    }
    
  },[])
  
  const handleOnClickToClipboard = (e) => {
    const {referralCode} = e.target.dataset;
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = `${constants.BASE_URL}/invite/u/${referralCode}`;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    alert('Referral code copied to clipboard');
  }
  const referralUrl = `${constants.BASE_URL}/invite/u/${customerData.referralCode}`
  const quote = `Use my code to enjoy $${firstTimeDiscount} off your first purchase at Red Dot Bespoke`;
  
  const handlePostToFacebook = () => {
    window.FB.init({
      appId            : '657656295121063',
      autoLogAppEvents : true,
      xfbml            : true,
      version          : 'v8.0'
    })
    window.FB.ui({
      method: 'share',
      href: referralUrl,
      hashtag: '#reddotbespoke',
      quote,
    }, function(response){

    });
  }

  const handlePostToTwitter = (params) => {
    window.open(`http://twitter.com/share?text=${quote}&url=${referralUrl}&hashtags=reddotbespoke`)
  }

  const handlePostToWA = () => {
    window.open(`https://wa.me/?text=Hi%20, ${quote}. Click ${referralUrl}`)
  }
  

  return (
    <Layout pageInfo={{ pageName: "profile" }}> 

      <Row>
        <Col className="text-center">
          <h5>Dashboard </h5>
        </Col>
      </Row>
      <Container className="mt-3">
        {showInfoAlert && 
          <Row><Col><Alert variant="info" dismissible onClose={() => setShowInfoAlert(false)}>{alertMsg}</Alert></Col></Row>
        }
        <Row>
          <Col lg={6} sm={12}>
            <Card className={`${styles.card}`}>
                <Card.Header className={`${styles.cardHeader} ${styles.cardBg} ${styles.cardHeaderDanger}` }>
                  <h6><span className={'oi oi-dollar'}></span> R Points</h6>
                </Card.Header>
                <Card.Body className={`text-center d-flex flex-column justify-content-between ${styles.cardBody}`}>
                  <Row>
                    <Col sm={6}>
                      You are able to use your R Points when purchasing any items from our store
                    </Col>
                    <Col sm={6} className={`text-center ${styles.lifetimeEarnings}`}>
                      Available Balance
                      <h1>${Number(rdbDollarsAmount).toFixed(2)}</h1>
                      <small className="text-muted">Expiring: {customerData && customerData.pointsExpiry ? DDMMYYYYFormat(customerData.pointsExpiry) : '-'}</small>

                    </Col>
                  </Row>
                </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={12}>
            <Card className={`${styles.card}`}>
                <Card.Header className={`${styles.cardHeader} ${styles.cardBg} ${styles.cardHeaderWarning}` }>
                  <h6><span className={'oi oi-people'}></span> Referrals</h6>
                </Card.Header>
                <Card.Body className={`text-center d-flex flex-column justify-content-between ${styles.cardBody}`}>
                  <Row>
                    <Col sm={6}>
                      <p>Referrals give you R Points from every purchase made by your referrals.</p> 
                      Invite more friends to earn more.
                    </Col>
                    <Col sm={6} className={`text-center ${styles.lifetimeEarnings}`}>
                      Total Referrals
                      <h1>{totalReferrals}</h1>
                    </Col>
                  </Row>
                </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5"> 
          <Col lg={3}>
            <Card className={`${styles.card}`}>
              <Card.Header className={`${styles.cardHeader} ${styles.cardBg} ${styles.cardHeaderSuccess}` }>
                <h6><span className="oi oi-inbox mr-2"></span>{pendingOrdersCount} Pending Orders</h6>
              </Card.Header>
              <Card.Body className={`text-center d-flex flex-column justify-content-between ${styles.cardBody}`}>
                <Card.Text>
                  {pendingOrdersCount ? 'View your pending order(s)' : 'All orders are fulfilled'}
                </Card.Text>
                <Button variant="secondary" 
                  onClick={()=> navigate(pendingOrdersCount ? '/profile/pending-orders' : '/profile/appointment', {
                    state: { 
                      name: customerData.name,
                      email: customerData.email,
                      phone: customerData.phone
                    }
                  })
                  }
                >
                  {pendingOrdersCount ? 'View Orders' : 'Set Appointment'}
                </Button>
              </Card.Body>
            </Card>    
          </Col>  
          <Col lg={3}>
            <Card className={`${styles.card}`}>
              <Card.Header className={`${styles.cardHeader} ${styles.cardBg} ${styles.cardHeaderSuccess}` }>
                <h6><span className="oi oi-fork mr-2"></span>Referrals</h6>
              </Card.Header>
              <Card.Body className={`text-center d-flex flex-column justify-content-between ${styles.cardBody}`}>
                <Card.Text>
                A list of people that has used your referral code.
                </Card.Text>
                <Button variant="secondary" 
                  onClick={()=> navigate('/profile/referrals')}
                >
                View
                </Button>
              </Card.Body>
            </Card>  
          
          </Col>  
          <Col lg={3}>
            <Card className={`${styles.card}`}>
              <Card.Header className={`${styles.cardHeader} ${styles.cardBg} ${styles.cardHeaderSuccess}` }>
                <h6><span className="oi oi-list mr-2"></span>Order History</h6>
              </Card.Header>
              <Card.Body className={`text-center d-flex flex-column justify-content-between ${styles.cardBody}`}>
                <Card.Text>
                  History of your orders
                </Card.Text>
                <Button variant="secondary" 
                  onClick={()=> navigate('/profile/history')}
                >View</Button>
              </Card.Body>
            </Card>  
          
          </Col>  
          <Col lg={3}>
            <Card className={`${styles.card}`}>
              <Card.Header className={`${styles.cardHeader} ${styles.cardBg} ${styles.cardHeaderSuccess}` }>
                <h6 className="text-center">
                  <span className="oi oi-share-boxed mr-2 float-left"></span>
                  <span style={{fontSize: '1.2rem'}}>{customerData && customerData.referralCode ? customerData.referralCode: ''}</span>
                  <span 
                      data-referral-code={customerData && customerData.referralCode ? customerData.referralCode : ''}
                      className="oi oi-clipboard pointer float-right" 
                      title="Copy to Clipboard" 
                      onClick={handleOnClickToClipboard}>
                  </span>
                </h6>
                
              </Card.Header>
              <Card.Body className={`text-center d-flex flex-column justify-content-between ${styles.cardBody}`}>
                <Card.Text>Share this code and get {levelOnePercentage}% from your first level and {levelTwoPercentage}% from your second level referral purchases (Terms and Conditions apply)</Card.Text>
                <DropdownButton as={ButtonGroup} variant="secondary" title="Share Code" id="bg-nested-dropdown">
                  <Dropdown.Item eventKey="1" data-referral-code={customerData && customerData.referralCode ? customerData.referralCode : ''} onClick={handleOnClickToClipboard}>
                    Copy Link
                  </Dropdown.Item>
                  {/* <Dropdown.Item eventKey="2" >
                    Send With WhatsApp
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3">
                    Send With Messenger
                  </Dropdown.Item> */}
                  <Dropdown.Item eventKey="4" onClick={handlePostToFacebook}>
                    Post To Facebook
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="4" onClick={handlePostToWA}>
                    Share via WhatsApp
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="5" onClick={handlePostToTwitter}>
                    Tweet
                  </Dropdown.Item>
                </DropdownButton>
              </Card.Body>
            </Card> 
          
          </Col>  
        </Row>  
      </Container>
    </Layout>
  )
}

export default CustomerProfile  