import React, {useState, useEffect} from 'react';
import Layout from '../../layout'
import Loader from '../../loading';
import { Container, Row, Col, Tab, Nav, Badge, Breadcrumb, Alert } from 'react-bootstrap'
import { navigate } from 'gatsby'
import FetchSetMore from '../../../helpers/fetchSetMore';
import StandardModal from '../../../components/modal';
import constants from '../../../helpers/constants';

import {DDMMYYYYFormat, DDMMYYYYFormatWithDay, setMoreFormat} from '../../../helpers/dateTime';
import loaderGif from '../../../images/ajax-loader.gif';
import styles from './setAppointment.module.css'


const SetAppointment = ({location}) => {
  const [showLoader, setShowLoader] = useState(false);
  const [services, setServices] = useState([]);
  const [chosenServiceDesc, setChosenServiceDesc] = useState('');
  const [providers, setProviders] = useState([]);
  const [chosenProviderDesc, setChosenProviderDesc] = useState('Amir');
  const [timeSlots, setTimeSlots] = useState([]);
  const [chosenTimeSlot, setChosenTimeSlot] = useState('');
  const [chosenTimeSlotDesc, setChosenTimeSlotDesc] = useState('');
  const [setMoreCustomerData, setSetMoreCustomerData] = useState({})
  const [timeSlotLoadingMsg, settimeSlotLoadingMsg] = useState('Getting Timeslots')
  const [showProviders, setShowProviders] = useState(false)
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [chosenService, setChosenService] = useState('');
  const [chosenProvider, setChosenProvider] = useState('');
  const [key, setKey] = useState('r52061542367623615');
  const [earliestDate, setEarliestDate] = useState([
      new Date(new Date().setDate(new Date().getDate() + 2)),
      new Date(new Date().setDate(new Date().getDate() + 3)),
      new Date(new Date().setDate(new Date().getDate() + 4)),
      new Date(new Date().setDate(new Date().getDate() + 5)),
      new Date(new Date().setDate(new Date().getDate() + 6)),
      new Date(new Date().setDate(new Date().getDate() + 7)),
      new Date(new Date().setDate(new Date().getDate() + 8)),
      new Date(new Date().setDate(new Date().getDate() + 9)),
      new Date(new Date().setDate(new Date().getDate() + 10)),
      new Date(new Date().setDate(new Date().getDate() + 11)),
  ]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState('');
  const [failMsg, setFailMsg] = useState('');
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);

  const getSetMoreAccessToken = () => {
    if (!constants.SETMORE_TOKEN) return;
    const url=`https://developer.setmore.com/api/v1/o/oauth2/token?refreshToken=${constants.SETMORE_TOKEN}`
    FetchSetMore.getSetMoreToken(url).then(res => {
      if (res && !res.response) {
        setShowFailAlert(true)
        setFailMsg(res.msg)
        return;
      }
      const promises = []
      promises.push(getServices());
      promises.push(getProviders());
      promises.push(getSetMoreCustomer())
      Promise.all(promises).then((values) => {
        setServices(values[0].data.services)
        const staffFromAPI = values[1].data.staffs
        const staffs = staffFromAPI.filter(staff => { 
          // return staff.first_name === 'Amir' || staff.first_name === 'Jolene' || staff.first_name === 'Nadya' || staff.first_name === 'Isabelle'
          return true;
        })
        setProviders(staffs)
        const customer = values[2].data.customer[0];
        setSetMoreCustomerData(customer);
      })
      .catch(error => { 
        console.error(error.message)
      });
    })
  }

  const getServices = () => {
    const url=`https://developer.setmore.com/api/v1/bookingapi/services`
    return FetchSetMore.get(url)
  }

  const getProviders = () => {
    const url=`https://developer.setmore.com/api/v1/bookingapi/staffs`
    return FetchSetMore.get(url)
  }

  const getSetMoreCustomer = () => {
    let queryString = '';
    const name = location && location.state && location.state.name ? location.state.name : '';
    const phone = location && location.state && location.state.phone ? location.state.phone : '';
    const email = location && location.state && location.state.email ? location.state.email : '';
    const queryStringArray = []
    if (name) {
      queryStringArray.push(`firstname=${name}`)
    }
    if (phone) {
      queryStringArray.push(`phone=${phone}`)
    }
    if (email) {
      queryStringArray.push(`email=${email}`)
    }
    const url = `https://developer.setmore.com/api/v1/bookingapi/customer?${queryStringArray.join("&")}`
    return FetchSetMore.get(url)
  }

  useEffect(() => {
    getSetMoreAccessToken()
    return () => {
    }
  }, [])

  const servicesList = () => {
    return (
      <>
      {services && services.length > 0 ? (
        <>
          {services.map(item => { 
            return <div 
              className={`pointer ${styles.services} ${chosenService === item.key ? styles.activeSelection : 'text-muted'}`} onClick={handleServiceClick} 
              key={item.key} 
              data-service-key={item.key}
              data-service-name={item.service_name}
            >
              {item.service_name} ({item.duration-item.buffer_duration} mins)
            </div> 
          })}
        </>
      ) : <><div><img style={{maxWidth: '20px'}} src={loaderGif}/>&nbsp;Please wait while we fetch our offering of services</div></>}
      </>
    )
  }

  const handleServiceClick = (e) => {
    setShowProviders(false)
    const {serviceKey, serviceName} = e.target.dataset
    setChosenService(serviceKey)
    setChosenServiceDesc(serviceName)
    setChosenProvider(key)
    setTimeSlots([]);
    getTimeSlots(serviceKey, key)
    setTimeout((() => {
      setShowProviders(true)
      setChosenProviderDesc('Amir')
    } ), 300);

  }

  const providersList = () => {
    return (
      <>
        <Tab.Container id="left-tabs-example"  defaultActiveKey={key}>
          <Row className="mt-3">
            <Col sm={2}>
              <Nav variant="pills" className="flex-column">
                {providers && providers.length > 0 ? (
                  <>
                    {providers.map(item => { return (
                      <Nav.Item key={item.key} >
                        <Nav.Link 
                          className={`pointer ${chosenProvider === item.key ? styles.activeProvider : 'text-muted'}`}
                          onClick={handleProviderClick} 
                          data-provider-key={item.key} 
                          data-provider-name={item.first_name.replace('&amp;','&')} 
                          eventKey={item.key} 
                          key={`NavLink${item.key}`}
                        >
                          {item.first_name.replace('&amp;','&')}
                        </Nav.Link>
                      </Nav.Item> )})
                    }
                  </>
                ) : <></>}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                {providers && providers.length > 0 ? (
                  <>
                    {providers.map(item => { 
                      return providerInfo(item)
                    }) 
                    }
                  </>
                ) : <></>}
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </>
    )
  }

  const providerInfo = (item) => {
    const commentArray = item.comment.split('\n\n')
    return (
      <Tab.Pane eventKey={item.key} key={`TabPane${item.key}`}>
        <div className="d-flex flex-columns my-3 align-items-center">
          <img style={{borderRadius: '100px', maxHeight:'10rem'}} className="mx-3" src={`${item.image_url}`}/>
          <div className="ml-3">
            <>
              {commentArray[0] ? commentArray[0].replace(/&#039;/g,"'").replace(/&quot;/g,'"').replace(/&amp;/g, "&") : ''}
              <br /><br />
              {commentArray[1] ? commentArray[1].replace(/&#039;/g,"'").replace(/&quot;/g,'"').replace(/&amp;/g, "&") : ''}
              <br /><br />
              {commentArray[2] ?commentArray[2].replace(/&#039;/g,"'").replace(/&quot;/g,'"').replace(/&amp;/g, "&") : ''}
              <br />
            </>
          </div>
        </div>
        <div className="my-5">{showTimeSlots ? timeSlotsList() : <>{timeSlotLoadingMsg}</>}</div>
      </Tab.Pane> 
    )
  }

  const handleProviderClick = (e) => {
    const {providerKey, providerName} = e.target.dataset;
    setChosenProvider(providerKey)
    setChosenProviderDesc(providerName)
    setTimeSlots([]);
    getTimeSlots(chosenService, providerKey)
  }

  const getTimeSlots = (serviceKey, providerKey) => {
    if (!serviceKey || !providerKey) return;
    const url=`https://developer.setmore.com/api/v1/bookingapi/slots`
    const promises = [];
    earliestDate.forEach((date) => {
        const data =  {
          "staff_key": providerKey,      
          "service_key": serviceKey,    
          "selected_date": DDMMYYYYFormat(date),
          "off_hours": false,
          "double_booking" : false,
          "slot_limit" : 30,
          "timezone"   : "Asia/Singapore"
        }
        promises.push(FetchSetMore.post(url, JSON.stringify(data)))
    })

    Promise.all(promises).then(res => {
      const dateWithSlots = [
        {date: DDMMYYYYFormatWithDay(earliestDate[0]), slots: res[0].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[1]), slots: res[1].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[2]), slots: res[2].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[3]), slots: res[3].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[4]), slots: res[4].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[5]), slots: res[5].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[6]), slots: res[6].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[7]), slots: res[7].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[8]), slots: res[8].data.slots},
        {date: DDMMYYYYFormatWithDay(earliestDate[9]), slots: res[9].data.slots},
      ];
      setShowTimeSlots(true)
      setTimeSlots(dateWithSlots)
    })
  }

  const timeSlotsList = () => {
    return (
      <>
      <h5 className="my-3"><u>Earliest Available Time Slot:</u></h5>
      {timeSlots && timeSlots.length > 0 ? (
        <>
          {timeSlots.map((item, index) => { return (
            <Row className={`my-3`}  key={index}>
              <Col lg={3}>{item.date}</Col>
              <Col lg={9}>
                {item.slots && item.slots.length > 0 ? item.slots.map((slot, index) => {
                  return <Badge 
                    className={`pointer ml-2 text-muted ${styles.badge}`} 
                    variant="secondary" 
                    onClick={handleTimeSlotClick} 
                    data-date-slot={item.date}
                    data-time-slot={slot}
                    key={`Badge${index}`}
                    >
                      {slot.replace('.',':')}
                    </Badge> 
                }): <div className="ml-2 text-muted">Fully booked</div>
                }
              </Col>
            </Row>
          )
          }) }
        </>
      ) : <><img style={{maxWidth: '52px'}} src={loaderGif}/></>}
      </>
    )
  }

  const handleTimeSlotClick = (e) => {
    const {timeSlot, dateSlot} = e.target.dataset
    
    const dateTime = setMoreFormat(dateSlot, timeSlot)
    setShowConfirmModal(true)
    setChosenTimeSlot(dateTime)
    setChosenTimeSlotDesc(`${dateSlot} ${timeSlot}`)
  }

  const handleOnConfirm = (e) => {
    setShowConfirmModal(false)
    setShowLoader(true);
    const url=`https://developer.setmore.com/api/v1/bookingapi/appointment/create`
    const data = {
      "staff_key" : chosenProvider,      
      "service_key" : chosenService,      
      "customer_key" : setMoreCustomerData.key,  
      "start_time" : `${chosenTimeSlot.startTime}`,     
      "end_time"  : `${chosenTimeSlot.endTime}`,
      "comment":"Comment",
      "label" :"Label"
    }

    FetchSetMore.post(url, JSON.stringify(data)).then(res => {
     if (res && !res.response) {
      setShowFailAlert(true)
      setFailMsg(res.msg);
      setShowLoader(false);
      return;
    }
      setShowSuccessAlert(true)
      setSuccessMsg(res.msg);
      getTimeSlots(chosenService, chosenProvider)
      setShowLoader(false);
    })
    
  }

  const handleConfirmClose = () => {
    setShowConfirmModal(false);
    setShowSuccessAlert(false);
    setShowFailAlert(false);
  }
  

  return (
    <Layout pageInfo={{ pageName: "appointements" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item active>Appointments</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col className="text-center mb-2">
            <h5>Set Appointments</h5>
            <h6><small>Choose your preferred service, consultant, and time for your clothing journey.</small></h6>
          </Col>
        </Row>
        <Row className={`d-flex flex-row justify-content-around my-3`}>
          {servicesList()}
        </Row>
        <hr />
        {showProviders ? providersList() : <></>}
        {/* {(<div><pre>{JSON.stringify(location && location.state, null, 2) }</pre></div>)} */}
        {/* {(<div><pre>{JSON.stringify(setMoreCustomerData, null, 2) }</pre></div>)} */}
      </Container>
      <StandardModal 
        onConfirm={handleOnConfirm}
        onClose={handleConfirmClose} 
        show={showConfirmModal}
        title="Confirm Appointment"
        body={``}
      >
        <Row><Col>Service Name : <strong>{chosenServiceDesc}</strong></Col></Row>
        <Row><Col>Consultant Name: <strong>{chosenProviderDesc}</strong></Col></Row>
        <Row><Col>Date & Time: <strong>{chosenTimeSlotDesc.replace(".",":")}</strong></Col></Row>
      </StandardModal>
      <StandardModal 
        onClose={handleConfirmClose} 
        show={showSuccessAlert}
        title="Status"
        body={``}
      >
        <Alert variant="success">{successMsg}</Alert>
      </StandardModal>
      <StandardModal 
        onClose={handleConfirmClose} 
        show={showFailAlert}
        title="Status"
        body={``}
      >
        <Alert variant="danger">{failMsg}</Alert>
      </StandardModal>
    </Layout>
  )
}

export default SetAppointment