import React, { useState, useEffect } from "react"
import { Container, Form, Row, Col, Button, Alert } from 'react-bootstrap'
import constants from '../../helpers/constants';
import { navigate, Link } from 'gatsby';
import Fetch from '../../helpers/fetch';
import getDecodedToken from '../../helpers/jwtToken'
import {  isTailor } from '../../helpers/roles'


const ChangePasswordFirstTime = () => {
  const [validated, setValidated] = useState(false);
  const [newPwd, setNewPwd] = useState('');
  const [newPwdConfirm, setNewPwdConfirm] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    const oldPwd = document.querySelector('#oldPwd').value;
    const newPwdElement = document.querySelector('#newPwdInput')
    const newPwdConfirmElement = document.querySelector('#newPwdConfirmInput')
    
    if (newPwdElement.checkValidity() === false) {
      if (newPwdElement.validity.valueMissing) {
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Please enter your new password"
      } else if (newPwdElement.validity.tooShort){
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Password needs to be 8 characters long"
      } else if (newPwdElement.validity.patternMismatch){
        document.querySelector("#newPwd.invalid-feedback").innerHTML="Password needs to have at least one uppercase character, one lowercase character, one number, and one special character ( !@#$%^&* ) "
      }
    }

    if (newPwdConfirmElement.checkValidity() === false) {
      if (newPwdConfirmElement.validity.valueMissing) {
        document.querySelector("#newPwdConfirm.invalid-feedback").innerHTML="Please confirm your new password"
      } else if (newPwdConfirmElement.validity.tooShort){
        document.querySelector("#newPwdConfirm.invalid-feedback").innerHTML="Password needs to be 8 characters long"
      } else if (newPwdElement.validity.patternMismatch){
        document.querySelector("#newPwdConfirm.invalid-feedback").innerHTML="Password needs to have at least one uppercase character, one lowercase character, one number, and one special character ( !@#$%^&* ) "
      }
    }

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    if (newPwd !== newPwdConfirm) {
      setShowError(true);
      setErrorMessage('New and Confirmation password does not match');
      return;
    }

    setValidated(false);
    const url=`${constants.BACKEND_URL}/users-auth/replacePassword`
    const data={
      oldPwd,
      newPwd,
      newPwdConfirm,
      username: getDecodedToken().username,
    }
    
    Fetch.post(url, JSON.stringify(data)).then(data => {
      if(data.error) {
        alert(`${data.error} - ${data.message ? data.message : ''} `)
        return;
      }
      if (isTailor()) {
        navigate('/orders/history', {
          state: {
            passwordChange: true
          }
        }) 
        return; 
      }
      navigate('/profile', {
        state: {
          passwordChange: true
        }
      })
    })
  }

  const handleItemChange = (e) => {
    const {property} = e.target.dataset;
    switch (property) {
      case 'newPwd':
        setNewPwd(e.target.value);
        break;
      case 'newPwdConfirm':
        setNewPwdConfirm(e.target.value);
        break;
      default:
        break;
    }
  }


  return (
    <>
    <Row>
      <Col className="text-center">
        <h5>Set New Password</h5>
        <h6><small>- Please change your password -</small></h6>
      </Col>      
    </Row>
    <Container>
      <Alert variant="info">
      New Password needs to be 8 characters long<br />
      New Password needs to have at least one uppercase character, one lowercase character, one number, and one special character ( !@#$%^&* ) 
      </Alert>
      {showError && (
        <>
          <Alert dismissible variant="danger" onClose={()=>{setShowError(false)}}>{errorMessage}</Alert>
        </>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Old Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control 
              required
              id={'oldPwd'} 
              type="password" 
              placeholder={'Old Password'} 
              // value={item['address']} 
              // onChange={handleItemChange} 
              // disabled={isDisabled}
              />
            <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            New Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control 
              required
              minLength={8}
              pattern={`${constants.passwordRegex}`}
              data-property="newPwd" 
              id={'newPwdInput'} 
              type="password" 
              placeholder={'New Password'} 
              value={newPwd} 
              onChange={handleItemChange}
              />
            <Form.Control.Feedback id="newPwd" type="invalid">Password needs to have minimum of 8 chars</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            Confirm New Password
          </Form.Label>
          <Col sm={10}>
            <Form.Control 
              required
              minLength={8}
              pattern={`${constants.passwordRegex}`}
              id={'newPwdConfirmInput'} 
              data-property="newPwdConfirm" 
              type="password" 
              placeholder={'New Password Confirmation'} 
              pattern={`${constants.passwordRegex}`}
              value={newPwdConfirm} 
              onChange={handleItemChange}
              />
            <Form.Control.Feedback id="newPwdConfirm" type="invalid">Please confirm your new password</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <div className="d-flex justify-content-center"><Button type="submit">Save</Button><Button variant="danger" className="ml-2">Cancel</Button></div>
      </Form>
    </Container>
    </>
  )
}

export default ChangePasswordFirstTime