import React, {useState, useEffect} from 'react';

import Layout from '../../layout'
import Loader from '../../loading';

import { Container, Row, Col, Alert, Button, Breadcrumb, Form } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {DDMMYYYYFormat} from '../../../helpers/dateTime';

const MyProfile = () => {
  const [profile, setProfile] = useState({})
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMsg, setSuccessAlertMsg] = useState('');
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [failAlertMsg, setFailAlertMsg] = useState('');

  const getCustomerDetail = () => {
    const url=`${constants.BACKEND_URL}/customers/customer/myProfile`
    Fetch.get(url).then(data => {
      setProfile(data.data)
    })
  }

  useEffect(() => {
    getCustomerDetail()
    return () => {
    }
  }, [])

  const handleOnChange = (e) => {
    const obj = {}
    obj[`${e.target.dataset.property}`] = e.target.value;
    setProfile({...profile, ...obj})
  } 


  const handleSaveButtonClick = (e) => {
    const url=`${constants.BACKEND_URL}/customers/customer/update`
    const {name, phone, address} = profile;
    const data = {
      name,
      phone,
      address
    }
    setShowLoader(true);
    Fetch.put(url, JSON.stringify(data)).then(res => {
     setShowLoader(false);
     if (!res.status) {
       setShowFailAlert(true);
       setShowSuccessAlert(false);
       setFailAlertMsg('Oopps, something went wrong. Please Try Again!')
       return;
     }
     setShowFailAlert(false);
     setShowSuccessAlert(true);
     setSuccessAlertMsg('Profile Update Success')
    })
  }

  return (
    <Layout pageInfo={{ pageName: "myProfile" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item active>My Profile</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col>
            <Alert variant="success" dismissible onClose={() => setShowSuccessAlert(false)} show={showSuccessAlert}>
              {successAlertMsg}
            </Alert>
            <Alert variant="danger" dismissible onClose={() => setShowFailAlert(false)} show={showFailAlert}>
              {failAlertMsg}
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col className="text-center mb-2">
            <h5>My Profile</h5>
            <h6><small>Showing your information details</small></h6>
          </Col>
        </Row>
        <Form>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>Name</Form.Label>
            <Col sm={8}>
              <Form.Control
                data-property="name"
                value={profile.name}
                onChange={handleOnChange}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>Email Address</Form.Label>
            <Col sm={8}>
              <Form.Control
                value={profile.email}
                disabled={true}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>Phone Number</Form.Label>
            <Col sm={8}>
              <Form.Control
                data-property="phone"
                value={profile.phone}
                onChange={handleOnChange}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>Date Of Birth</Form.Label>
            <Col sm={8}>
              <Form.Control
                value={DDMMYYYYFormat(profile.dob)}
                disabled={true}
              ></Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={2}>Address</Form.Label>
            <Col sm={8}>
              <Form.Control
                data-property="address"
                value={profile.address}
                onChange={handleOnChange}
              ></Form.Control>
            </Col>
          </Form.Group>
        </Form>
        <Row>
          <Col>
            <Button onClick={handleSaveButtonClick}>Save</Button>
            <Button className="ml-3" variant="secondary" onClick={() => navigate('/profile')}>Go Back</Button>
          </Col>
        </Row>
      {/* {(<div><pre>{JSON.stringify(profile, null, 2) }</pre></div>)} */}
      </Container>
    </Layout>
  )
}

export default MyProfile