import React, {useState, useEffect} from 'react';
import Layout from '../../layout'
import Loader from '../../loading';

import { Container, Row, Col, Table, Button, Breadcrumb } from 'react-bootstrap'
import { navigate } from 'gatsby'
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {DDMMYYYYFormat} from '../../../helpers/dateTime';

const PendingOrders = ({location}) => {
  const [pendingOrders, setPendingOrders] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  
  const getPendingOrders = () => {
    setShowLoader(true);

    const url=`${constants.BACKEND_URL}/orders/customer/pending-orders`
    Fetch.get(url).then(res => {
      if (res.error) {
        alert(res.error)
      }
      setPendingOrders(res.data)
      setShowLoader(false);

    })
    
  }

  useEffect(() => {
    getPendingOrders();
    return () => {
    }
  }, [])

  return (
    <Layout pageInfo={{ pageName: "pendingOrders" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item active>Pending Orders</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col className="text-center mb-2">
            <h5>Pending Orders</h5>
            <h6><small>Showing Orders in Process</small></h6>
          </Col>
        </Row>
        <Row>
          <Table responsive>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Order Date</th>
                <th>Completed Date</th>
                <th>Attended By</th>
                <th>Price</th>
                <th>Deposit</th>
                <th>Balance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pendingOrders && Array.isArray(pendingOrders) ? 
                pendingOrders.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>{DDMMYYYYFormat(item.time)}</td>
                      <td>{DDMMYYYYFormat(item.balancePaid)}</td>
                      <td>{item.consultant_name}</td>
                      <td>${(Number(item.balance) + Number(item.deposit)).toFixed(2)}</td>
                      <td>${(Number(item.deposit)).toFixed(2)}</td>
                      <td><span className={Number(item.balance) > 0 ? 'text-danger':'' }> ${(Number(item.balance)).toFixed(2)}</span></td>
                      <td>
                        <Button onClick={() => navigate(`/profile/order/${item.id}`,{
                            state: {
                              from: '/profile/pending-orders',
                              pageName: 'Pending Orders'
                            }
                          })}
                        >View Details</Button>
                      </td>
                    </tr>
                  )
                }) : <tr></tr>
              }
            </tbody>
          </Table>
        </Row>

      </Container>
    </Layout>
  )
}

export default PendingOrders