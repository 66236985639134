import React from "react"
import { Router } from "@reach/router"
import PrivateRoute from '../components/private/privateRoute'
import ChangePasswordFirstTime from "../components/private/changePasswordFirstTime"
import CustomerProfile from "../components/private/customerProfile"
import ProfileHistory from "../components/private/profile/history"
import OrderDetails from "../components/private/profile/orderDetails"
import PendingOrders from "../components/private/profile/pendingOrders"
import Referrals from "../components/private/profile/referrals"
import SecondLevelReferrals from "../components/private/profile/secondLevelReferrals"
import BonusTransactions from "../components/private/profile/bonusTransactions"
import MyProfile from "../components/private/profile/myProfile"
import SetAppointment from "../components/private/profile/setAppointment"
import Default from "../components/default"

const Profile = () => {
  return (
    <Router basepath="/profile">
      <PrivateRoute path="/" component={CustomerProfile} />
      <PrivateRoute path="/changePassword" component={ChangePasswordFirstTime} />
      <PrivateRoute path="/history" component={ProfileHistory} />
      <PrivateRoute path="/pending-orders" component={PendingOrders} />
      <PrivateRoute path="/order/:orderId" component={OrderDetails} />
      <PrivateRoute path="/referrals" component={Referrals} />
      <PrivateRoute path="/referrals/:referralCode" component={SecondLevelReferrals} />
      <PrivateRoute path="/bonus" component={BonusTransactions} />
      <PrivateRoute path="/myProfile" component={MyProfile} />
      <PrivateRoute path="/appointment" component={SetAppointment} />
      <Default path="/" />
    </Router>
  )
}
export default Profile