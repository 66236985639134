import React, {useState, useEffect} from 'react';
import Layout from '../../layout'
import Loader from '../../loading';

import { Container, Row, Col, Table, Button, Breadcrumb } from 'react-bootstrap'
import { navigate } from 'gatsby'
import getDecodedToken from '../../../helpers/jwtToken'
import Fetch from '../../../helpers/fetch';
import constants from '../../../helpers/constants';
import {DDMMYYYYFormat} from '../../../helpers/dateTime';

const ProfileHistory = ({location}) => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [showLoader, setShowLoader] = useState(false)
  
  const getOrderHistory = () => {
    setShowLoader(true);

    const url=`${constants.BACKEND_URL}/orders/customer/history`
    Fetch.get(url).then(res => {
      if (res.error) {
        alert(res.error)
      }
      setOrderHistory(res.data)
      setShowLoader(false);

    })
    
  }

  useEffect(() => {
    getOrderHistory();
    return () => {
    }
  }, [])

  return (
    <Layout pageInfo={{ pageName: "history" }}> 
      <Loader show={showLoader}></Loader>
      
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate('/profile')}>Overview</Breadcrumb.Item>
          <Breadcrumb.Item active>Order History</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col className="text-center mb-2">
            <h5>History</h5>
            <h6><small>Showing past orders</small></h6>
          </Col>
        </Row>
        <Row>
          {orderHistory && Array.isArray(orderHistory) && orderHistory.length > 0 ?  (
            <Table responsive>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Order Date</th>
                  <th>Completed Date</th>
                  <th>Attended By</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {orderHistory && Array.isArray(orderHistory) ? 
                  orderHistory.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{DDMMYYYYFormat(item.time)}</td>
                        <td>{DDMMYYYYFormat(item.balancePaid)}</td>
                        <td>{item.consultant_name}</td>
                        <td>${(Number(item.balance) + Number(item.deposit)).toFixed(2)}</td>
                        <td><Button onClick={() => navigate(`/profile/order/${item.id}`,{
                        state: {
                          from: '/profile/history',
                          pageName: 'History'
                        }
                      })}>View Details</Button></td>
                      </tr>
                    )
                  }) : <></>
                }
              </tbody>
            </Table>
          ) : 'No Completed Orders'
          }
        </Row>

      </Container>
    </Layout>
  )
}

export default ProfileHistory