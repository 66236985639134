

const getLocaleMySQLDateFormatFromUTCDate = (UTCDateString) => {
  //make it to a local date and time
  const localeDateTimeString = new Date(UTCDateString).toLocaleString();

  const dateObject = new Date(localeDateTimeString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const date = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();

  const mySQLFormat = `${year}-${month}-${date}`;

  return mySQLFormat;
}

const getUTCDateObjectFromUTCDateString = (UTCDateString) => {
  const dateObject = new Date(UTCDateString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth();
  const day = dateObject.getDate();
  const hour = dateObject.getHours();
  const min = dateObject.getMinutes();
  const sec = dateObject.getSeconds();

  return Date.UTC(year, month, day, hour, min, sec);
}

const ReactDatePickerFormat = (dateString) => {
  if (!dateString) {
    return null;
  }
  
  if(dateString === '0000-00-00') {
    return null;
  }

  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return null;
  }

  return dateObject
}

const DOSDDMMYYYYFormat = (dateString) => {
  // WE NEED THIS BECAUSE DATE OF SALES (DOS) IS IN UTC FORMAT
  // GETDATE() WILL USE LOCALE STRING, WHICH ADD 8 HOURS (SG TIME)
  if (!dateString) {
    return "--";
  }
  
  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const year = dateObject.getUTCFullYear();
  const month = dateObject.getUTCMonth() + 1 < 10 ? `0${dateObject.getUTCMonth() + 1}` : dateObject.getUTCMonth() + 1;
  const date = dateObject.getUTCDate() < 10 ? `0${dateObject.getUTCDate()}` : dateObject.getUTCDate();

  const DDMMYYYYFormat = `${date}/${month}/${year}`;
  return DDMMYYYYFormat 
}

const YYYYMMDDFormat = (dateString, plain=false) => {
  if (!dateString) {
    return "--";
  }
  
  if(dateString === '0000-00-00') {
    return "";
  }

  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const date = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();

  return plain ? `${year}${month}${date}` : `${year}-${month}-${date}`
}

const DDMMYYYYFormat = (dateString, plain=false) => {
  if (!dateString) {
    return "--";
  }
  
  if(dateString === '0000-00-00') {
    return "";
  }

  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const date = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();

  return plain ? `${date}${month}${year}` : `${date}/${month}/${year}`
}

const DDMMFormat = (dateString, plain=false) => {
  if (!dateString) {
    return "--";
  }
  
  if(dateString === '0000-00-00') {
    return "";
  }

  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const date = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();

  return plain ? `${date}${month}` : `${date}/${month}`
}

const DDMMYYYYFormatWithTime = (dateString) => {
  if (!dateString) {
    return "--";
  }
  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "--";
  }
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const date = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();
  const hour = dateObject.getHours() < 10 ? `0${dateObject.getHours()}` : dateObject.getHours();
  const min = dateObject.getMinutes() < 10 ? `0${dateObject.getMinutes()}` : dateObject.getMinutes();
  const sec = dateObject.getSeconds() < 10 ? `0${dateObject.getSeconds()}` : dateObject.getSeconds();

  const DDMMYYYYFormat = `${date}/${month}/${year}, ${hour}:${min}:${sec}`;
  return DDMMYYYYFormat 
}

const DDMMYYYYFormatWithDay = (dateString) => {
  if (!dateString) {
    return "--";
  }
  const dateObject = new Date(dateString);
  if (isNaN(dateObject)) {
    return "--";
  }

  const weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1 < 10 ? `0${dateObject.getMonth() + 1}` : dateObject.getMonth() + 1;
  const date = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate();
  const day = weekday[dateObject.getDay()];

  const DDMMYYYYFormat = `${day}, ${date}/${month}/${year}`;
  return DDMMYYYYFormat 
}

const setMoreFormat = (dateSlot, timeSlot) => {
  const datePart = dateSlot.split(', ')[1].split("/");
  const year = datePart[2];
  const month = Number(datePart[1]) < 10 ? `0${Number(datePart[1])}` : `${Number(datePart[1])}`;
  const date = datePart[0];
  
  const timePart = timeSlot.split('.');
  const startHour = timePart[0];
  const endHour = Number(timePart[0])+1;
  const mins = timePart[1];

  return  {
    startTime: `${year}-${month}-${date}T${startHour}:${mins}Z`,
    endTime: `${year}-${month}-${date}T${endHour}:${mins}Z`
  }
  
  return ;
}

const generateMonths=(monthsBehind, monthsForward) => {
  const monthsArray = [];
  for (let i = monthsBehind; i >= 0; i--) {
    const now = new Date();
    now.setDate(2);
    const monthsAgo = new Date(now.setMonth(now.getMonth() - i)).toISOString().split("T")[0].split("-");
    const yymm = `${monthsAgo[0]}-${monthsAgo[1]}`
    monthsArray.push(yymm)
  }

  if (monthsForward) {
    for (let i = 1; i <= monthsForward; i++) {
      const now = new Date();
      now.setDate(2);
      const monthsAhead = new Date(now.setMonth(now.getMonth() + i)).toISOString().split("T")[0].split("-");
      const yymm = `${monthsAhead[0]}-${monthsAhead[1]}`
      monthsArray.push(yymm)
    }
  }
  
  return monthsArray;    
}

const addDays = (dateString, noOfDays) => {
  if (!dateString) {
    return "--";
  }
  const dateObject = new Date(dateString)
  if (isNaN(dateObject)) {
    return "--";
  }
  dateObject.setDate(dateObject.getDate() + noOfDays);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() < 10 ? `0${dateObject.getMonth()}` : dateObject.getMonth();

  const date = dateObject.getDate() < 10 ? Number(`0${dateObject.getDate()}`) : Number(dateObject.getDate());

  return new Date(year, month, date);
}

const substractDays = (dateString, noOfDays) => {
  if (!dateString) {
    return "--";
  }
  const dateObject = new Date(dateString)
  if (isNaN(dateObject)) {
    return "--";
  }
  
  dateObject.setDate(dateObject.getDate() - noOfDays);
  
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() < 10 ? `0${dateObject.getMonth()}` : dateObject.getMonth();

  const date = dateObject.getDate() < 10 ? Number(`0${dateObject.getDate()}`) : Number(dateObject.getDate());
  
  return new Date(year, month, date);
}

export {getLocaleMySQLDateFormatFromUTCDate, getUTCDateObjectFromUTCDateString, DOSDDMMYYYYFormat, DDMMYYYYFormat, DDMMYYYYFormatWithTime, DDMMYYYYFormatWithDay, generateMonths, addDays, substractDays, ReactDatePickerFormat, DDMMFormat, YYYYMMDDFormat, setMoreFormat}