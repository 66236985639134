import React, { Component } from "react"
import { navigate } from "gatsby"
// import { isLoggedIn } from "../services/auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const isLoggedIn = () => {
    return true;
  }


  if (!isLoggedIn() && location.pathname !== `/login`) {
    navigate("/login")
    return null
  }
  return <Component location={location} {...rest} />
}
export default PrivateRoute